<template>
  <div>
    <input
      v-model="animationURL"
      class="classic-input"
      type="text"
      @change="loadAnimation"
    >

    <LottieWebVue
      v-if="animationData"
      :key="key"
      class="animation"
      :animation-data="animationData"
    />
  </div>
</template>

<script>
import axios from 'axios';
import LottieWebVue from 'lottie-web-vue';

export default {
  components: {
    LottieWebVue,
  },
  data() {
    return {
      animationURL: 'https://raw.githubusercontent.com/garbit/lottie-web-vue/main/src/assets/watermelon.json',
      key: new Date().toString(),
      animationData: null,
    };
  },
  async mounted() {
    this.loadAnimation();
  },
  methods: {
    async loadAnimation() {
      this.animationData = (await axios.get(this.animationURL)).data;
      this.key = new Date().toString();
    },
  },
};
</script>

<style scoped>
input {
  width: 100%;
}

.animation {
  width: 100%;
  height: 100%;
}
</style>
